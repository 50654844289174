:root {
  --white-color: #FFFFFF;
  --lightgrey-color: #CCCCCC;
  --grey-color: #707070;
  --black-color: #3E3E3E;
  --blue-color: #2A52A4;
  --green-color: #007E2A;
  --red-color: #FF0000;
  --border-color: #ECECEC;
}

body {
  animation: fade-in 1.2s;
  transition-delay: .01s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: var(--white-color);
  border: 1px solid var(--border-color);
  border-radius: 30px;
}

::-webkit-scrollbar-thumb {
  background: var(--blue-color);
  border-radius: 30px;
  border: 1px solid var(--border-color);
  height: 157px;
}

.dropdown-menu {
  padding: 10px 30px;
  &.active {
    color: rgb(0, 111, 238);
    font-weight: 700;
  }
  .title {

  }
}


.showfer-admin {
  width: 100vw;

  .body {
    width: 100vw;
  }

  .header {
    width: 100vw;
    display: flex;
    background: #0a0909e5;
    box-shadow: 0 3px 6px #00000029;
    opacity: 1;
    height: 80px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
    font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;

    &-home-link {
      width: 180px;
      min-width: 180px;
      margin: 0 18px 0 60px;
    }

    &-logo {
      width: 100%;
    }

    &-menu {
      display: flex;
      width: calc(100% - 116px);
      align-items: center;

      &-left {
        display: flex;
        width: 40%;
        text-align: left;
        gap: 8px;
      }

      &-right {
        display: flex;
        gap: 8px;
        width: 55%;
        justify-content: flex-end;
      }

      &-item {
        font-size: 18px;
        color: #FFFFFF;
        padding: 10px 16px;
        position: relative;
        border-radius: 999px;

        &:hover {
          background: rgb(32, 32, 32);
        }

        &.active {
          color: rgb(0, 111, 238);
          font-weight: 700;
        }
        &.dropdown {
          .title {
            padding-right: 5px;
          }
        }
      }
    }
  }
}

.ant-table-filter-trigger.active {
  color: var(--blue-color);
}

.ant-pagination-item {
  transition: .3s ease-out;

  &:hover {
    border-color: var(--blue-color);

    a {
      color: var(--blue-color);
    }
  }

  &-active {
    border-color: var(--blue-color);

    a {
      color: var(--blue-color);
    }
  }
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: var(--blue-color);
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-color: var(--blue-color);
}

.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon, .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon {
  display: none;
}

.ant-switch-checked {
  background-color: var(--blue-color);
}

.showfer-button {
  cursor: pointer;
  background: var(--border-color) 0 0 no-repeat padding-box;
  box-shadow: 0 3px 6px #00000029;
  border: none;
  border-radius: 10px;
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: .3s ease-out;

  span {
    color: var(--black-color);
  }

  &:focus {
    background: var(--border-color) 0 0 no-repeat padding-box;
  }

  &:hover, &.primary-button {
    background: var(--blue-color) 0 0 no-repeat padding-box;
  }

  &:hover, &.primary-button, &.success-button, &.danger-button {
    box-shadow: inset 0 3px 8px #00000066, 0 3px 6px #00000029;
    color: var(--white-color);

    span {
      color: var(--white-color);
    }
  }

  &.success-button {
    background: var(--green-color) 0 0 no-repeat padding-box;
  }

  &.danger-button {
    background: var(--red-color) 0 0 no-repeat padding-box;
  }

  &.default-button {
    background: var(--white-color);

    &:hover {
      color: var(--black-color);

      span {
        color: var(--black-color);
      }
    }
  }

  &[disabled], &[disabled]:hover, &[disabled]:focus, &[disabled]:active {
    color: var(--black-color);
    background: var(--border-color) 0 0 no-repeat padding-box;
    box-shadow: none;

    span {
      color: rgba(62, 62, 62, 0.5);
    }
  }
}

.ant-dropdown-menu {
  background: #202020;
  font-size: 16px;
  border-radius: 8px;
  padding: 8px 0;
  min-width: 200px;

  &-item {
    color: #FFFFFF;
    font-size: 16px;
    font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
    padding: 8px 16px;

    &:hover {
      background: #303030;
    }
  }
}
