.access-denied-container {
    width: 100vw;
    height: calc(100vh - 100px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;

    h1 {
        font-size: 200px;
        line-height: 150px;
        margin: 0;
    }

    h2 {
        margin: 0;
        font-size: 50px;
        color: var(--blue-color);
    }

    p {
        font-size: 20px;
        color: var(--blue-color);
        font-weight: bold;
    }

    a {
        color: #fff;
        border: 1px solid var(--blue-color);
        background-color: var(--blue-color);
        border-radius: 8px;
        padding: 10px 20px;
        font-size: 16px;
        font-weight: bold;

        &:hover {
            background-color: #17439c;
        }
    }
}