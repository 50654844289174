.loginPage {
    position: relative;
    height: 100vh;
    display: flex;
    align-items: center;
}

.home {
    position: absolute;
    top: 51px;
    left: -102px;
    width: 308px;
    height: 114px;
    background: #2A52A4 0 0 no-repeat padding-box;
    box-shadow: 0 6px 6px #00000029;
    border-radius: 45px;
    opacity: 1;
    display: flex;
    align-items: center;
}

.iconHome {
    cursor: pointer;
    margin: 0 40px 0 auto;
}

.loginInner {
    margin: 0 auto;
    text-align: center;
}

.loginTitle {
    color: #2A52A4;
    margin-bottom: 30px;
}

.loginInput {
    color: #2A52A4;
    width: 100%;
    margin: 20px 0 30px;
    border-bottom: 5px solid #2A52A4;
    border-top: none;
    border-right: none;
    border-left: none;
    outline: none;
    font-size: 18px;
    font-weight: 600;
}

::placeholder {
    color: #2A52A4;
    font-size: 18px;
    font-weight: 600;
}

.loginDescription {
    color: #2A52A4;
}

.loginBtn {
    cursor: pointer;
    font-size: 35px;
    background-color: #2A52A4;
    text-transform: uppercase;
    color: white;
    border: none;
    width: 290px;
    height: 80px;
    border-radius: 45px;
    margin-top: 20px;
    font-weight: 600;
}

.loginBtn:hover {
    background-color: white;
    color: #2A52A4;
}

.loginLogo {
    position: absolute;
    top: 50px;
    right: calc(50vw - (309px/2));
    width: 309px;
}

.errorText {
    color: red;
}