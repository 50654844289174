body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  box-sizing: border-box;
}

/*.pageBorder {*/
/*  height: 100vh;*/
/*  box-shadow: 0 3px 40px #00000033;*/
/*  padding: 50px 20px 0;*/
/*  overflow: auto;*/
/*}*/
